@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

svg.delete-button {
  position: absolute;
  right: -21px;
  top: -20px;
  bottom: auto;
  left: auto
}


@tailwind base;
@tailwind components;
@tailwind utilities;
